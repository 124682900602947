import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/Seo';
import Layout from '../components/Layout';
import * as Page from '../ui-components/page';
import * as Grid from '../ui-components/grid';

const NewLicensePage = () => (
  <Layout>
    <SEO
      title="New NoStack License"
      keywords={[`no-stack`, `gatsby`, `javascript`, `react`]}
    />

    <Grid.MainSection>
      <Grid.Container>
        <Page.PageHeader>
          <Page.PageTitle>Create a NoStack License</Page.PageTitle>
        </Page.PageHeader>

        <Page.PageMessage>
          <p>We are currently working on automating license creation.</p>

          <p>
            Meanwhile, you may request for a license by{' '}
            <a href="mailto:yyakovson613@gmail.com">sending us an email</a>.
          </p>

          <p>
            Already have a license?{' '}
            <Link to="/new-stack">Create a stack instead.</Link>
          </p>
        </Page.PageMessage>
      </Grid.Container>
    </Grid.MainSection>
  </Layout>
);

export default NewLicensePage;
