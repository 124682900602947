import styled from 'styled-components';

import { Row, Col6 } from './grid';
import variables from './variables';

export const ArticleContent = styled.div`
  p {
    color: ${variables.textColor}
    font-size: 20px;
    line-height: 32px;

    @media only screen and (max-width: 460px) {
      font-size: 18px;
      line-height: 24px;   
    }
  }

  @media only screen and (max-width: 900px) {
    ${Row} {
      display: block;

      ${Col6} {
        width: 100%;
      }

      ${Col6} ~ ${Col6} {
        margin-top: 20px;
      }
    }
  }
`;

export const ArticleSubtitle = styled.div`
  color: ${variables.textColorSubtitle};
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  padding: 23px 0 0;

  @media only screen and (max-width: 624px) {
    font-size: 18px;
    line-height: 24px;
    padding: 10px 0 0;
  }
`;

export const ArticleTitle = styled.h2`
  color: ${variables.textColor};
  font-size: 40px;
  line-height: 50px;
  text-align: center;

  @media only screen and (max-width: 624px) {
    font-size: 24px;
    line-height: 32px;
  }
`;
