import styled from 'styled-components';
import { Link } from 'gatsby';

import { ArticleSubtitle } from './articles';
import variables from './variables';

export const PageLink = styled(Link)``;

export const PageMessage = styled(ArticleSubtitle)`
  padding: 0 0 200px;
  text-align: left;

  p {
    margin: 25px 0;
  }

  a,
  ${PageLink} {
    color: ${variables.colors.green};
  }
`;

export const PageTitle = styled.h1`
  color: ${variables.textColor};
  font-size: 40px;
  line-height: 50px;
  text-align: center;

  @media only screen and (max-width: 624px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const PageHeader = styled.header`
  padding: 50px 0;

  @media only screen and (max-width: 460px) {
    padding: 25px 0;
  }
`;
